import React from "react";

interface FeatureCardProps {
    title: string;
    value: string;
    description: string;
    icon: React.ReactNode; // Add icon property
}

const FeatureCard: React.FC<FeatureCardProps> = ({ title, value, description, icon }) => {
    return (
        <div className="feature-card p-4 rounded-lg text-center">
            <div className="icon mb-2">{icon}</div> {/* Add icon here */}
            <h4 className="text-2xl font-semibold text-gray-800 mb-2">{title}</h4>
            <p className="text-5xl font-bold text-blue-500 mb-2">{value}</p>
            <p className="text-lg text-gray-700">{description}</p>
        </div>
    );
};

export default FeatureCard;
