import React, { useRef, useEffect, useState } from "react";

const HeroSection: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleLoadedData = () => {
        setLoading(false);
      };

      const handleTimeUpdate = () => {
        if (video.currentTime >= 10) { // End the video at 10 seconds
          video.currentTime = 0; // Restart the video
        }
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("timeupdate", handleTimeUpdate);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, []);

  return (
    <section className="relative h-screen flex items-center justify-center bg-gray-700">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-20">
          <div className="loader"></div>
        </div>
      )}

      {/* Vidéo en arrière-plan */}
      <video
        ref={videoRef}
        className={`absolute top-0 left-0 w-full h-full object-cover ${loading ? 'hidden' : 'block'}`}
        src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2FPXL_20220808_025646376.mp4?alt=media&token=20dc2366-6992-4e76-8e8e-8a5286de6873"
        autoPlay
        loop
        muted
      ></video>

      {/* Contenu par-dessus la vidéo */}
      <div className="relative px-4 mx-auto max-w-screen-xl text-center text-white z-10">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl">
          Association Teoraoraraka Polynésie Française
        </h1>
        <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
          Chez Teoraoraraka, nous nous concentrons sur la préservation de la culture polynésienne, l'innovation et la promotion du développement durable.
        </p>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
          <a
            href="/contact"
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
          >
            Nous contacter
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
              aria-hidden="true"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
          <a
            href="/about"
            className="inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 hover:text-gray-900 focus:ring-4 focus:ring-gray-400"
          >
            En savoir plus
          </a>
        </div>
      </div>

      {/* Overlay sombre pour meilleure lisibilité */}
      <div className="absolute inset-0 bg-black opacity-30"></div>
    </section>
  );
};

export default HeroSection;