import React from "react";
import ChangeEmailForm from "./forms/ChangeEmailForm.tsx";
import ResetPasswordForm from "./forms/ResetPasswordForm.tsx";
import DeleteAccountForm from "./forms/DeleteAccountForm.tsx";

const SettingsSection: React.FC = () => {
  return (
    <div className="p-6 bg-gray-800 shadow-lg rounded-md">
      <h1 className="text-3xl font-bold text-white mb-4">Paramètres</h1>
      <p className="text-gray-300 mb-6">
        Modifiez vos paramètres et vos préférences ici.
      </p>

      <div className="space-y-8">
        <ChangeEmailForm />
        <ResetPasswordForm />
        <DeleteAccountForm />
      </div>
    </div>
  );
};

export default SettingsSection;