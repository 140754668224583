import React from "react";

interface BlogSidebarProps {
  author: string;
}

const BlogSidebar: React.FC<BlogSidebarProps> = ({ author }) => {
  return (
    <div className="sticky top-0 start-0 py-8 lg:ps-8">
      {/* Author Information */}
      <div className="group flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 dark:border-neutral-700">
        <div>
          <h5 className="text-sm font-semibold text-gray-800 dark:text-neutral-200">{author}</h5>
          <p className="text-sm text-gray-500 dark:text-neutral-500">Auteur de l'article</p>
        </div>
      </div>

      {/* Related Posts */}
      <div className="space-y-6">
        <a className="group flex items-center gap-x-6 focus:outline-none" href="#">
          <div className="grow">
            <span className="text-sm font-bold text-gray-800 dark:text-neutral-200">
              Explorer la biodiversité polynésienne
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default BlogSidebar;
