import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogList from './BlogList.tsx';
import BlogArticle from './BlogArticle.tsx';

const BlogRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BlogList />} />
      <Route path=":id" element={<BlogArticle />} />
    </Routes>
  );
};

export default BlogRoutes;
