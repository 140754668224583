import React, { createContext, useState, useEffect, ReactNode } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import auth from "../services/firebase/firebaseAuth.ts";

interface AuthContextProps {
    user: User | null;
    isEmailVerified: boolean;
    isLoading: boolean;
    error: string | null;
    login: (user: User) => void;
    logout: () => void;
    setError: (error: string | null) => void;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            setIsLoading(true);
            try {
                if (currentUser) {
                    await currentUser.reload(); // Recharger les informations utilisateur
                    setUser(currentUser);
                    setIsEmailVerified(currentUser.emailVerified || false);
                } else {
                    setUser(null);
                    setIsEmailVerified(false);
                }
            } catch (err) {
                console.error("Erreur lors de la mise à jour de l'état de l'utilisateur :", err);
                setError(err instanceof Error ? err.message : "Une erreur est survenue.");
            } finally {
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    // Vérification périodique de la vérification par e-mail
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (user && !user.emailVerified) {
            interval = setInterval(async () => {
                try {
                    await user.reload();
                    setIsEmailVerified(user.emailVerified);
                } catch (err) {
                    console.error("Erreur lors de la vérification de l'email :", err);
                    setError(err instanceof Error ? err.message : "Une erreur est survenue.");
                }
            }, 5000); // Vérifie toutes les 5 secondes
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [user]);

    const login = (user: User) => {
        setUser(user);
        setIsEmailVerified(user.emailVerified || false);
        setError(null);
    };

    const logout = async () => {
        try {
            await auth.signOut();
            setUser(null);
            setIsEmailVerified(false);
        } catch (err) {
            console.error("Erreur lors de la déconnexion :", err);
            setError(err instanceof Error ? err.message : "Une erreur est survenue.");
        }
    };

    return (
        <AuthContext.Provider
            value={{ user, isEmailVerified, isLoading, error, login, logout, setError }}
        >
            {children}
        </AuthContext.Provider>
    );
};
