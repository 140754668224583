import React from 'react';

const BlogSidebar: React.FC = () => {
  return (
    <div className="sticky top-0 start-0 py-8 lg:ps-8">
      {/* Author Information */}
      <div className="group flex items-center gap-x-3 border-b border-gray-200 pb-8 mb-8 dark:border-neutral-700">
        <a className="block shrink-0 focus:outline-none" href="#">
        </a>
        <a className="group grow block focus:outline-none" href="#">
          <h5 className="text-sm font-semibold text-gray-800 dark:text-neutral-200">
            Matthieu B
          </h5>
          <p className="text-sm text-gray-500 dark:text-neutral-500">
            Président de l'association
          </p>
        </a>
      </div>

      {/* Related Posts */}
      <div className="space-y-6">
        <a className="group flex items-center gap-x-6 focus:outline-none" href="#">
          <div className="grow">
            <span className="text-sm font-bold text-gray-800 dark:text-neutral-200">
              Explorer la riche biodiversité de la Polynésie : un voyage dans les merveilles écologiques
            </span>
          </div>
          <div className="shrink-0 relative rounded-lg overflow-hidden size-20">
            <img
              className="absolute top-0 start-0 object-cover rounded-lg w-full h-full"
              src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fimage-c4.png?alt=media&token=25c85f6d-fc63-4dfb-ba58-aabe76ac0ea9"
              alt="Blog Image"
            />
          </div>
        </a>
        <a className="group flex items-center gap-x-6 focus:outline-none" href="#">
          <div className="grow">
            <span className="text-sm font-bold text-gray-800 dark:text-neutral-200">
              L'artisanat polynésien : un savoir-faire ancestral
            </span>
          </div>
          <div className="shrink-0 relative rounded-lg overflow-hidden size-20">
            <img
              className="absolute top-0 start-0 object-cover rounded-lg w-full h-full"
              src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Ftestimonial.jpg?alt=media&token=9a0f58d9-b0c6-4964-93f3-866409a2c3c3"
              alt="Blog Image"
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default BlogSidebar;
