import React, { useState, useEffect } from "react";
import ProfileTabs from "./SectionsTabs/ProfileTabs.tsx";
import GeneralSection from "./Sections/GeneralSection.tsx";
import NotificationsSection from "./Sections/NotificationsSection.tsx";
import PreferencesSection from "./Sections/PreferencesSection.tsx";
import { getUserProfile, updateUserProfile } from "../../services/firestore/userCollection.ts";
import { getAuth } from "firebase/auth";

const ProfileSection: React.FC = () => {
  const auth = getAuth();
  const [activeTab, setActiveTab] = useState("general");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    preferences: [],
    notifications: {
      emailNotifications: false,
      smsNotifications: false,
      pushNotifications: false,
    },
    language: "fr",
    theme: "light",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser?.uid) {
        try {
          setLoading(true);
          const userData = await getUserProfile(auth.currentUser.uid);
          if (userData) {
            setFormData({
              ...formData,
              ...userData,
            });
          }
        } catch (err) {
          console.error("Error fetching user data:", err);
          setError("Erreur lors du chargement des données utilisateur.");
        } finally {
          setLoading(false);
        }
      } else {
        setError("Utilisateur non authentifié.");
        setLoading(false);
      }
    };

    fetchData();
  }, [auth.currentUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
  
    setFormData((prevState) => ({
      ...prevState,
      notifications: {
        ...prevState.notifications,
        [name]: checked, // Met à jour la notification correspondante
      },
    }));
  };

  const handleSave = async () => {
    if (!auth.currentUser?.uid) return;

    try {
      setLoading(true);
      await updateUserProfile(auth.currentUser.uid, formData);
      setIsEditing(false);
      setError(null);
    } catch (err) {
      console.error("Error updating user data:", err);
      setError("Erreur lors de la mise à jour des informations.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="text-gray-300">Chargement des données...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-800 shadow-lg rounded-md">
      <h1 className="text-3xl font-bold text-white mb-4">Profil</h1>
      <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === "general" && (
        <GeneralSection
          formData={formData}
          isEditing={isEditing}
          handleChange={handleChange}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
      {activeTab === "notifications" && (
        <NotificationsSection
          formData={formData}
          isEditing={isEditing}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
      {activeTab === "preferences" && (
        <PreferencesSection
          formData={formData}
          isEditing={isEditing}
          handleChange={handleChange}
        />
      )}

      {isEditing ? (
        <button
          type="button"
          onClick={handleSave}
          className="mt-8 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500"
        >
          Appliquer les changements
        </button>
      ) : (
        <button
          type="button"
          onClick={() => setIsEditing(true)}
          className="mt-8 px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-500"
        >
          Modifier
        </button>
      )}
    </div>
  );
};

export default ProfileSection;