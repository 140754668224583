import React from "react";

interface NotificationsSectionProps {
  formData: {
    notifications: {
      emailNotifications: boolean;
      smsNotifications: boolean;
      pushNotifications: boolean;
    };
  };
  isEditing: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NotificationsSection: React.FC<NotificationsSectionProps> = ({
  formData,
  isEditing,
  handleCheckboxChange,
}) => (
  <form className="mt-6 space-y-6">
    <h2 className="text-xl font-bold text-white mb-4">Notifications</h2>

    <div className="space-y-4">
      <label className="flex items-center">
        <input
          type="checkbox"
          name="emailNotifications"
          checked={formData.notifications.emailNotifications}
          onChange={handleCheckboxChange}
          disabled={!isEditing}
          className={`w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 ${
            !isEditing ? "cursor-not-allowed opacity-50" : ""
          }`}
        />
        <span className="ml-2 text-gray-300">Recevoir des notifications par email</span>
      </label>

      <label className="flex items-center">
        <input
          type="checkbox"
          name="smsNotifications"
          checked={formData.notifications.smsNotifications}
          onChange={handleCheckboxChange}
          disabled={!isEditing}
          className={`w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 ${
            !isEditing ? "cursor-not-allowed opacity-50" : ""
          }`}
        />
        <span className="ml-2 text-gray-300">Recevoir des notifications par SMS</span>
      </label>

      <label className="flex items-center">
        <input
          type="checkbox"
          name="pushNotifications"
          checked={formData.notifications.pushNotifications}
          onChange={handleCheckboxChange}
          disabled={!isEditing}
          className={`w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500 ${
            !isEditing ? "cursor-not-allowed opacity-50" : ""
          }`}
        />
        <span className="ml-2 text-gray-300">Recevoir des notifications push</span>
      </label>
    </div>
  </form>
);

export default NotificationsSection;
