import React from "react";
import FeatureCard from "./FeatureCard.tsx";
import { FaTemperatureHigh, FaCloudRain, FaSun } from "react-icons/fa"; // Import icons

const FeaturesSection: React.FC = () => {
    const features = [
        {
            title: "Température Moyenne",
            value: "15°C",
            description: "Température moyenne annuelle",
            icon: <FaTemperatureHigh />, // Add icon
        },
        {
            title: "Précipitations",
            value: "1200mm",
            description: "Précipitations moyennes annuelles",
            icon: <FaCloudRain />, // Add icon
        },
        {
            title: "Jours Ensoleillés",
            value: "200",
            description: "Nombre moyen de jours ensoleillés par an",
            icon: <FaSun />, // Add icon
        },
    ];

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
                {features.map((feature, index) => (
                    <FeatureCard
                        key={index}
                        title={feature.title}
                        value={feature.value}
                        description={feature.description}
                        icon={feature.icon} // Pass icon
                    />
                ))}
            </div>
        </div>
    );
};

export default FeaturesSection;
