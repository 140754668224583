import React from 'react';

const NotFound: React.FC = () => {
    return (
        <div style={styles.container}>
            <h1>404 - Page Not Found</h1>
            <p>The page you're looking for doesn't exist.</p>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        textAlign: 'center',
        marginTop: '50px',
    },
};

export default NotFound;