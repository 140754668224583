import React from "react";
import Accordion from "../../components/shared/Accordion.tsx";
import HeroSection from "../../components/shared/HeroSection.tsx";
import TestimonialsSection from "../../components/shared/Testimonial/TestimonialsSection.tsx";
import TestimonialsSectionShort from "../../components/shared/Testimonial/TestimonialsSectionShort.tsx";
import BlogArticle from '../../components/BlogArticle/BlogArticle.tsx';

const Home: React.FC = () => {
  return (
    <div className="home font-['Poppins']">
      <HeroSection />

      <div className="mx-auto px-4 my-8">
        <div className="w-full h-px bg-gray-300 my-4"></div>
        <BlogArticle />
      </div>

      <div className="mx-auto px-4">
        <div className="w-full h-px bg-gray-300 my-4"></div>
        <h2 className="text-3xl text-gray-800 text-center font-semibold">
          Association culturelle polynésienne
        </h2>
        <p className="text-lg text-gray-700 text-center mt-2">
          Découvrez notre culture et nos traditions
        </p>
        <Accordion />
      </div>

      <div className="mx-auto px-4 my-8">
        <div className="w-full h-px bg-gray-300 my-4"></div>
        <h2 className="text-3xl text-gray-800 text-center font-semibold">
          Nos Objectifs Face au Changement Climatique
        </h2>
        <p className="text-lg text-gray-700 text-center mt-2">
          Rester à l'écoute des besoins de notre communauté
        </p>
      </div>
      <TestimonialsSection />
      <TestimonialsSectionShort />

    </div>
  );
};

export default Home;
