import React, { useState } from "react";

// FAQHeader Component
const FAQHeader: React.FC = () => (
  <div className="md:col-span-2">
    <div className="max-w-xs">
    <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
      Questions<br />fréquemment posées
    </h2>
    <p className="mt-1 hidden md:block text-gray-600 dark:text-neutral-400">
      Réponses aux questions les plus fréquemment posées.
    </p>
    </div>
  </div>
);

// FAQItem Component
interface FAQItemProps {
  id: string;
  question: string;
  answer: string;
  isActive?: boolean;
}

const FAQItem: React.FC<FAQItemProps> = ({ id, question, answer, isActive }) => {
  const [active, setActive] = useState(isActive);

  const toggleActive = () => {
    setActive(!active);
  };

  return (
    <div className={`hs-accordion pt-6 pb-3 ${active ? "active" : ""}`} id={id}>
      <button
        className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 focus:outline-none focus:text-gray-500 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400"
        aria-expanded={active}
        aria-controls={`${id}-collapse`}
        onClick={toggleActive}
      >
        {question}
        <svg
          className="hs-accordion-active:hidden block shrink-0 size-5 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
        <svg
          className="hs-accordion-active:block hidden shrink-0 size-5 text-gray-600 group-hover:text-gray-500 dark:text-neutral-400"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m18 15-6-6-6 6" />
        </svg>
      </button>
      <div
        id={`${id}-collapse`}
        className={`hs-accordion-content ${active ? "block" : "hidden"} w-full overflow-hidden transition-[height] duration-300`}
        role="region"
        aria-labelledby={id}
      >
        <p className="text-gray-600 dark:text-neutral-400">{answer}</p>
      </div>
    </div>
  );
};

// FAQList Component
const FAQList: React.FC = () => {
const faqItems = [
    {
        id: "faq-1",
        question: "Qu'est-ce qu'une association écologique?",
        answer:
            "Une association écologique est une organisation à but non lucratif qui se consacre à la protection de l'environnement et à la promotion de pratiques durables.",
        isActive: true,
    },
    {
        id: "faq-2",
        question: "Comment puis-je rejoindre une association écologique?",
        answer:
            "Vous pouvez rejoindre une association écologique en visitant leur site web, en remplissant un formulaire d'adhésion et en payant une cotisation annuelle si nécessaire.",
    },
    {
        id: "faq-3",
        question: "Quels sont les avantages de devenir membre?",
        answer:
            "En devenant membre, vous pouvez participer à des événements, recevoir des newsletters, accéder à des ressources exclusives et contribuer activement à la protection de l'environnement.",
    },
    {
        id: "faq-4",
        question: "Comment puis-je faire un don?",
        answer:
            "Vous pouvez faire un don en ligne via le site web de l'association, par virement bancaire ou en envoyant un chèque à l'adresse de l'association.",
    },
    {
        id: "faq-5",
        question: "Quels types de projets l'association mène-t-elle?",
        answer:
            "L'association mène divers projets tels que la reforestation, la protection des espèces menacées, la sensibilisation à l'environnement et la promotion de l'énergie renouvelable.",
    },
    {
        id: "faq-6",
        question: "Comment puis-je participer aux événements de l'association?",
        answer:
            "Vous pouvez participer aux événements en vous inscrivant via le site web de l'association ou en contactant directement les organisateurs pour plus d'informations.",
    },
];

  return (
    <div className="hs-accordion-group divide-y divide-gray-200 dark:divide-neutral-700">
      {faqItems.map((item) => (
        <FAQItem
          key={item.id}
          id={item.id}
          question={item.question}
          answer={item.answer}
          isActive={item.isActive}
        />
      ))}
    </div>
  );
};

// Main FAQSection Component
const FAQSection: React.FC = () => (
  <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    <div className="grid md:grid-cols-5 gap-10">
      <FAQHeader />
      <div className="md:col-span-3">
        <FAQList />
      </div>
    </div>
  </div>
);

export default FAQSection;
