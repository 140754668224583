// src/components/shared/TeamMembers.tsx

import React from "react";

const TeamMembers = () => {
  const members = [
    {
      name: "Matthieu B",
      role: "Président",
      bio: "étudiant en ingénieurie informatique, initiateur du projet Teoraoraraka",
      image:
        "https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fpresident.c7ed59aa4ae09b8031ac.png?alt=media&token=f88cb692-396d-4084-8ba1-fcbd1e4bf8f1",
    },
    {
      name: "Mathilde B",
      role: "Vice-Présidente - Trésorière",
      bio: "étudiante en ecole de commerce, responsable de la gestion financière de l'association",
      image:
        "https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fvice-president.52cc238e0a0d8f419ca6.png?alt=media&token=8bd23260-4452-4ea7-8be3-379071233b9c",
    },
  ];

  return (
    <div className="max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 mx-auto text-center">
      {/* Title */}
      <div className="max-w-2xl mx-auto mb-10 lg:mb-14">
        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
          Membres de Teoraoraraka
        </h2>
      </div>
      {/* Team Members */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 justify-center">
        {members.map((member, index) => (
          <div
            key={index}
            className="flex flex-col rounded-xl p-4 md:p-6 bg-white border border-gray-200 dark:bg-neutral-900 dark:border-neutral-700"
          >
            <div className="flex items-center gap-x-4">
              <img
                className="rounded-full size-20"
                src={member.image}
                alt={member.name}
              />
              <div className="grow text-left">
                <h3 className="font-medium text-gray-800 dark:text-neutral-200">
                  {member.name}
                </h3>
                <p className="text-xs uppercase text-gray-500 dark:text-neutral-500">
                  {member.role}
                </p>
              </div>
            </div>
            <p className="mt-3 text-gray-500 dark:text-neutral-500">
              {member.bio}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMembers;
