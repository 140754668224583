import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

export const handleEmailLinkSignIn = async (email: string): Promise<string> => {
  const auth = getAuth();
  const emailLink = window.location.href;

  if (isSignInWithEmailLink(auth, emailLink)) {
    try {
      await signInWithEmailLink(auth, email, emailLink);
      return "success";
    } catch (error) {
      console.error("Error during sign-in with email link:", error);
      return "error";
    }
  }

  return "invalid";
};
