import React from "react";
import BlogCard from "./BlogCard.tsx";

const BlogCardContainer = () => {
  const cards = [
    {
      id: 2, // ID for dynamic routing
      image:
        "https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fimage-c2.png?alt=media&token=a9a5d00d-ffa5-4d34-8dd7-92cf1e81a4ed",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fpresident.c7ed59aa4ae09b8031ac.png?alt=media&token=f88cb692-396d-4084-8ba1-fcbd1e4bf8f1",
      name: "Matthieu B",
      date: "Jan 09, 2025",
      title: "L'artisanat polynésien : un savoir-faire ancestral",
      description:
        "L'art de l'artisanat polynésien est un savoir-faire ancestral qui a été transmis de génération en génération...",
    },
    {
      id: 3, // ID for dynamic routing
      image:
        "https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2FtestimoniShort.jpg?alt=media&token=b8f5f627-9de9-4944-af47-6639f8dacfaf",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fpresident.c7ed59aa4ae09b8031ac.png?alt=media&token=f88cb692-396d-4084-8ba1-fcbd1e4bf8f1",
      name: "Matthieu B",
      date: "Jan 15, 2025",
      title:
        "Explorer la riche biodiversité de la Polynésie : un voyage dans les merveilles écologiques",
      description:
        "La Polynésie est un véritable paradis pour les amoureux de la nature. Découvrez les merveilles écologiques...",
    },
  ];

  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="grid lg:grid-cols-2 gap-6">
        {cards.map((card) => (
          <BlogCard key={card.id} {...card} />
        ))}
      </div>
    </div>
  );
};

export default BlogCardContainer;
