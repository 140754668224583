import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore";
import app from "../firebase/firebaseApp.ts";

const db = getFirestore(app);

// Récupérer tous les articles
export const fetchArticles = async () => {
  const articlesRef = collection(db, "articles");
  const snapshot = await getDocs(articlesRef);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

// Récupérer un article spécifique par son ID
export const fetchArticleById = async (articleId: string) => {
  const docRef = doc(db, "articles", articleId);
  const snapshot = await getDoc(docRef);

  if (snapshot.exists()) {
    return { id: snapshot.id, ...snapshot.data() };
  } else {
    throw new Error("Article introuvable");
  }
};

export const getArticleById = async (id: string) => {
    try {
      const docRef = doc(db, "articles", id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      throw error;
    }
  };