import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-white border-b border-gray-200 dark:bg-gray-900 w-full">
      <div className="max-w-screen-xl mx-auto p-6 lg:py-8">
        {/* Main Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          <div>
            <a href="/" className="flex items-center mb-4">
              <span className="text-2xl font-bold text-gray-800 dark:text-white">
                Teoraoraraka
              </span>
            </a>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              Découvrez et soutenez nos initiatives pour la préservation des
              Tuamotu.
            </p>
          </div>

          {/* Resources and Follow Us */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="text-sm font-semibold uppercase mb-4 text-gray-800 dark:text-white">
                Ressources
              </h3>
              <ul className="text-sm text-gray-600 dark:text-gray-400 space-y-2">
                <li>
                  <a href="/projects" className="hover:underline">
                    Nos Projets
                  </a>
                </li>
                <li>
                  <a href="/events" className="hover:underline">
                    Événements
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold uppercase mb-4 text-gray-800 dark:text-white">
                Suivez-nous
              </h3>
              <ul className="text-sm text-gray-600 dark:text-gray-400 space-y-2">
                <li>
                  <a
                    href="https://instagram.com/teoraoraraka"
                    className="hover:underline"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://x.com/teoraoraraka" className="hover:underline">
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Newsletter */}
          <div>
            <h3 className="text-sm font-semibold uppercase mb-4 text-gray-800 dark:text-white">
              Newsletter
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
              Abonnez-vous pour recevoir nos dernières nouvelles et événements.
            </p>
          </div>
        </div>

        {/* Separator */}
        <hr className="border-gray-300 dark:border-gray-700 mb-6" />

        {/* Footer Bottom */}
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            © 2025{" "}
            <a href="/" className="hover:underline">
              Teoraoraraka™
            </a>
            . Tous droits réservés.
          </p>
          <div className="flex space-x-4 mt-4 sm:mt-0">
            <a
              href="#"
              className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="#"
              className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="#"
              className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="#"
              className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white"
            >
              <i className="fab fa-pinterest"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
