import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../services/auth/index.ts";
import { addUserToCollection } from "../../services/firestore/userCollection.ts";

const Register: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (formData.password !== formData.confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      const response = await registerUser(formData.email, formData.password);
      if (response.success) {
        await addUserToCollection(
          response.user.uid,
          formData.email,
          formData.firstName,
          formData.lastName
        );
        setSuccess(true);
        setTimeout(() => navigate("/profile"), 2000);
      } else {
        setError(response.error);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <section className="min-h-screen flex">
      {/* Formulaire côté gauche */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-8 py-12 bg-gray-50 dark:bg-gray-900">
        <div className="max-w-lg mx-auto">
          <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
            Rejoingnez {" "}
            <span className="text-blue-600 dark:text-blue-500">Teoraoraraka</span>
          </h1>
          <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
            Devenez membre de notre association pour accéder à des ressources uniques.
          </p>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {error && (
              <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
            )}
            {success && (
              <p className="text-sm text-green-600 dark:text-green-400">
                Compte créé avec succès ! Vérifiez votre email pour confirmer
                votre compte.
              </p>
            )}

            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium dark:text-white"
              >
                Prénom
              </label>
              <input
                type="text"
                id="firstName"
                className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                placeholder="Prénom"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium dark:text-white"
              >
                Nom
              </label>
              <input
                type="text"
                id="lastName"
                className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                placeholder="Nom"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium dark:text-white"
              >
                Adresse email
              </label>
              <input
                type="email"
                id="email"
                className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium dark:text-white"
              >
                Mot de passe
              </label>
              <input
                type="password"
                id="password"
                className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                placeholder="Mot de passe"
                value={formData.password}
                onChange={handleChange}
              />
            </div>

            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium dark:text-white"
              >
                Confirmer le mot de passe
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                placeholder="Confirmer le mot de passe"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </div>

            <button
              type="submit"
              className="w-full py-3 px-4 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 focus:outline-none"
            >
              S'inscrire
            </button>
          </form>
        </div>
      </div>

      {/* Image côté droit */}
      <div className="hidden lg:block lg:w-1/2 bg-cover bg-center" style={{ backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fimage-c5.png?alt=media&token=6efc284e-fe37-4478-b67e-62176cfc0692')" }} />
    </section>
  );
};

export default Register;
