// src/pages/Legal/PrivacyPolicy.tsx
import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-page p-4">
      <h1 className="text-3xl font-bold mb-4">Politique de Confidentialité</h1>
      <p>
        Votre confidentialité est importante pour nous. Consultez cette page
        pour savoir comment nous protégeons vos données personnelles.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
