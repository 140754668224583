import React from "react";
import Avatar from "./Avatar.tsx";

interface BlogCardProps {
  id: number;
  image: string;
  avatar: string;
  name: string;
  date: string;
  title: string;
  description: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  id,
  image,
  avatar,
  name,
  date,
  title,
  description,
}) => {
  return (
    <a
      className="group relative block rounded-xl focus:outline-none"
      href={`/blog/${id}`}
    >
      <div className="shrink-0 relative rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:z-[1] before:size-full before:bg-gradient-to-t before:from-gray-900/70">
        <img
          className="size-full absolute top-0 start-0 object-cover"
          src={image}
          alt={`Blog Image for ${title}`}
        />
      </div>

      <div className="absolute top-0 inset-x-0 z-10">
        <div className="p-4 flex flex-col h-full sm:p-6">
          <Avatar avatar={avatar} name={name} date={date} />
        </div>
      </div>

      <div className="absolute bottom-0 inset-x-0 z-10">
        <div className="flex flex-col h-full p-4 sm:p-6">
          <h3 className="text-lg sm:text-3xl font-semibold text-white group-hover:text-white/80 group-focus:text-white/80">
            {title}
          </h3>
          <p className="mt-2 text-white/80">{description}</p>
        </div>
      </div>
    </a>
  );
};

export default BlogCard;
