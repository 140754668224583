import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home.tsx";
import About from "./pages/About/About.tsx";
import Projects from "./pages/Projects/Projects.tsx";
import Events from "./pages/Events/Events.tsx";
import Contact from "./pages/Contact/Contact.tsx";
import PrivacyPolicy from "./pages/Legal/PrivacyPolicy.tsx";
import Terms from "./pages/Legal/Terms.tsx";
import Login from "./pages/Auth/Login.tsx";
import Register from "./pages/Auth/Register.tsx";
import Profile from "./pages/Profile/Profile.tsx";
import Layout from "./components/layout/Layout.tsx";
import AuthLayout from "./components/layout/AuthLayout.tsx";
import ProtectedRoute from "./components/shared/ProtectedRoute.tsx";
import ResetPassword from "./pages/Auth/ResetPassword.tsx";
import NotFound from "./pages/Error/NotFound.tsx";
import BlogRoutes from "./pages/Blog/BlogRoutes.tsx";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <AuthLayout>
              <Login />
            </AuthLayout>
          }
        />
        <Route
          path="/register"
          element={
            <AuthLayout>
              <Register />
            </AuthLayout>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Layout>
                <Profile />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <Layout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/events" element={<Events />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
          }
        />
         <Route
          path="/blog/*"
          element={
            <Layout>
              <BlogRoutes />
            </Layout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;