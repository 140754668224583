import React from "react";
import {
  UserCircleIcon,
  ChartBarIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

interface SidebarProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab }) => {
  const menuItems = [
    {
      id: "profile",
      label: "Profil",
      icon: <UserCircleIcon className="w-5 h-5 mr-2" />,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <ChartBarIcon className="w-5 h-5 mr-2" />,
    },
    {
      id: "settings",
      label: "Paramètres",
      icon: <Cog6ToothIcon className="w-5 h-5 mr-2" />,
    },
  ];

  return (
    <nav className="w-64 bg-gray-800 p-4">
      <ul className="space-y-4">
        {menuItems.map((item) => (
          <li key={item.id}>
            <button
              className={`flex items-center w-full text-left px-4 py-3 rounded-lg ${
                activeTab === item.id
                  ? "bg-blue-700 text-white"
                  : "bg-gray-700 text-gray-300 hover:bg-gray-600"
              }`}
              onClick={() => setActiveTab(item.id)}
            >
              {item.icon}
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidebar;
