import React from "react";
import ContactForm from "./ContactForm.tsx";
import ContactInfoCard from "./ContactInfoCard.tsx";

const ContactUsSection: React.FC = () => {
    return (
        <div className="max-w-7xl mx-auto px-4 py-8">
            <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800 text-center sm:text-4xl dark:text-white">Contactez-nous</h1>
                <p className="mt-1 text-gray-600 dark:text-neutral-400">
                    Nous sommes là pour soutenir vos initiatives écologiques.
                </p>
            </div>
            <div className="flex flex-col lg:flex-row gap-8">
                <div className="w-full lg:w-2/3">
                    <ContactForm />
                </div>
                <div className="w-full lg:w-1/3">
                    <div className="divide-y divide-gray-200 dark:divide-neutral-800">
                        <ContactInfoCard
                            title="Nos projets écologiques"
                            description="Nous œuvrons pour protéger l’environnement et soutenir la biodiversité."
                            linkText="Voici nos projets"
                            href="/projects"
                            icon={
                                <svg
                                    className="size-6 mt-1.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <circle cx="12" cy="12" r="10" />
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                    <path d="M12 17h.01" />
                                </svg>
                            }
                        />
                        <ContactInfoCard
                            title="Foire aux questions"
                            description="Retrouvez des réponses sur nos actions, projets et valeurs écologiques."
                            linkText="Visiter la FAQ"
                            href="/about"
                            icon={
                                <svg
                                    className="size-6 mt-1.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                                    <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                                </svg>
                            }
                        />
                        <ContactInfoCard
                            title="Adhérer à l’association"
                            description="Apprenez comment devenir membre et contribuer à la préservation de la planète."
                            linkText="Contactez-nous"
                            href="/contact"
                            icon={
                                <svg
                                    className="size-6 mt-1.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="m7 11 2-2-2-2" />
                                    <path d="M11 13h4" />
                                    <rect width="18" height="18" x="3" y="3" rx="2" ry="2" />
                                </svg>
                            }
                        />
                        <ContactInfoCard
                            title="Projet Futur"
                            description="Retrouvez des réponses sur nos actions, projets et valeurs écologiques."
                            linkText="Visiter la FAQ"
                            href="/projects"
                            icon={
                                <svg
                                    className="size-6 mt-1.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                                    <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                                </svg>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsSection;
