import React, { useState } from "react";
import { updateUserEmail } from "../../../services/auth/index.ts";

const ChangeEmailForm: React.FC = () => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const handleChangeEmail = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent page reload
    setError(null);
    setSuccess(null);

    if (!currentEmail || !password || !newEmail) {
      setError("Tous les champs doivent être remplis.");
      return;
    }

    try {
      const response = await updateUserEmail(currentEmail, password, newEmail);
      if (response.success) {
        setSuccess("Email mis à jour avec succès !");
        setError(null);
      } else {
        setError(response.error || "Erreur lors de la mise à jour de l'email.");
        setSuccess(null);
      }
    } catch (err: any) {
      setError(err.message || "Une erreur inattendue s'est produite.");
      setSuccess(null);
    }
  };

  return (
    <form
      onSubmit={handleChangeEmail}
      className="max-w-lg mx-auto bg-gray-800 shadow-lg rounded-lg p-6 space-y-6"
    >
      <h2 className="text-2xl font-bold text-gray-100">Changer d'email</h2>

      {/* Current Email Input */}
      <div className="space-y-2">
        <label
          htmlFor="current-email"
          className="block text-sm font-medium text-gray-300"
        >
          Adresse email actuelle
        </label>
        <input
          type="email"
          id="current-email"
          placeholder="Entrez votre email actuel"
          className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-gray-100 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          value={currentEmail}
          onChange={(e) => setCurrentEmail(e.target.value)}
          required
        />
      </div>

      {/* Password Input */}
      <div className="space-y-2">
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-300"
        >
          Mot de passe
        </label>
        <input
          type="password"
          id="password"
          placeholder="••••••••"
          className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-gray-100 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      {/* New Email Input */}
      <div className="space-y-2">
        <label
          htmlFor="new-email"
          className="block text-sm font-medium text-gray-300"
        >
          Nouvelle adresse email
        </label>
        <input
          type="email"
          id="new-email"
          placeholder="Entrez votre nouvelle adresse email"
          className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-gray-100 placeholder-gray-400 focus:ring-2 focus:ring-blue-500 focus:outline-none"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          required
        />
      </div>

      {/* Success & Error Messages */}
      {success && (
        <p className="text-sm text-center text-green-500 bg-green-100 rounded-lg py-2">
          {success}
        </p>
      )}
      {error && (
        <p className="text-sm text-center text-red-500 bg-red-100 rounded-lg py-2">
          {error}
        </p>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full py-3 text-center text-white bg-blue-600 hover:bg-blue-700 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        Mettre à jour
      </button>
    </form>
  );
};

export default ChangeEmailForm;
