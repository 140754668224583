import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth.ts";
import Sidebar from "../../components/navigation/Sidebar.tsx";
import ProfileSection from "../../components/profile/ProfileSection.tsx";
import DashboardSection from "../../components/profile/DashboardSection.tsx";
import SettingsSection from "../../components/profile/SettingsSection.tsx";

const Profile: React.FC = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState("profile");

  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return <ProfileSection userEmail={user?.email} />;
      case "dashboard":
        return <DashboardSection />;
      case "settings":
        return <SettingsSection />;
      default:
        return null;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-900 text-gray-100">
      {/* Menu latéral */}
      <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Contenu principal */}
      <main className="flex-grow p-4">{renderContent()}</main>
    </div>
  );
};

export default Profile;

