import React from "react";
import CardBlogImage from "./CardBlogImage.tsx";
import CardBlogContent from "./CardBlogContent.tsx";

const CardBlogContainer = () => {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div className="grid sm:grid-cols-2 sm:items-center gap-8">
        <CardBlogImage />
        <CardBlogContent />
      </div>
    </div>
  );
};

export default CardBlogContainer;
