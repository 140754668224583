import React from 'react';
import BlogContent from './BlogContent.tsx';
import BlogSidebar from './BlogSidebar.tsx';

const BlogArticle: React.FC = () => {
  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6">
        <div className="lg:col-span-2">
          <BlogContent />
        </div>
        <div className="lg:col-span-1">
          <BlogSidebar />
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
