// src/pages/Events/Events.tsx
import React from "react";

const Events: React.FC = () => {
  return (
    <div
      className="bg-cover bg-center h-screen flex items-center justify-center relative"
      style={{
        backgroundImage:
          "url('https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fimage-c3.png?alt=media&token=e9eabdea-0cfa-4571-9fa9-ca7e413a0fec')",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <h2 className="text-white text-5xl font-bold z-10">
        -Les Événements Arrivent Bientôt-
      </h2>
    </div>
  );
};

export default Events;
