import React, { useState, useEffect } from "react";
import { getUserMessages } from "../../services/firestore/contactCollection.ts";
import { getAuth } from "firebase/auth";

const DashboardSection: React.FC = () => {
  const auth = getAuth();
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!auth.currentUser?.uid) {
        setError("Utilisateur non authentifié.");
        setLoading(false);
        return;
      }

      try {
        const userMessages = await getUserMessages(auth.currentUser.uid);
        setMessages(userMessages);
      } catch (err) {
        console.error("Erreur lors de la récupération des messages :", err);
        setError("Erreur lors de la récupération des messages.");
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [auth.currentUser]);

  if (loading) {
    return <div className="text-gray-300">Chargement des messages...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-gray-800 shadow-lg rounded-md">
      <h1 className="text-3xl font-bold text-white mb-4">Dashboard</h1>
      <p className="text-gray-300 mb-4">
        {messages.length} {messages.length === 1 ? "message envoyé" : "messages envoyés"}
      </p>

      {messages.length > 0 && (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">Nom</th>
                <th scope="col" className="px-6 py-3">Email</th>
                <th scope="col" className="px-6 py-3">Message</th>
                <th scope="col" className="px-6 py-3">Statut</th>
                <th scope="col" className="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((msg) => (
                <tr
                  key={msg.id}
                  className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                >
                  <td className="px-6 py-4 font-medium text-gray-900 dark:text-white">{msg.name}</td>
                  <td className="px-6 py-4">{msg.email}</td>
                  <td className="px-6 py-4">{msg.message}</td>
                  <td className="px-6 py-4">{msg.status}</td>
                  <td className="px-6 py-4">
                    <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DashboardSection;