import React from "react";

interface ContactInfoCardProps {
  title: string;
  description: string;
  linkText?: string;
  href?: string;
  icon: React.ReactNode;
}

const ContactInfoCard: React.FC<ContactInfoCardProps> = ({ title, description, linkText, href, icon }) => {
  return (
    <div className="flex gap-x-8 py-6">
      <div className="shrink-0 text-gray-800 dark:text-neutral-200">{icon}</div>
      <div className="grow">
        <h3 className="font-semibold text-gray-800 dark:text-neutral-200">{title}</h3>
        <p className="mt-1 text-sm text-gray-500 dark:text-neutral-500">{description}</p>
        {linkText && href && (
          <a
            href={href}
            className="mt-2 inline-flex items-center gap-x-2 text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-neutral-400 dark:hover:text-neutral-200"
          >
            {linkText}
          </a>
        )}
      </div>
    </div>
  );
};

export default ContactInfoCard;
