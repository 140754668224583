import React from "react";

const TestimonialsSection: React.FC = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16 mx-auto">
        {/* Gradients */}
        <div
          aria-hidden="true"
          className="flex absolute start-0 -z-[1]"
        >
          <div className="bg-purple-200 opacity-20 blur-3xl w-[1036px] h-[300px] dark:bg-purple-900 dark:opacity-20"></div>
        </div>
        {/* End Gradients */}

        {/* Grid */}
        <div className="lg:grid lg:grid-cols-6 lg:gap-8 lg:items-center">
          {/* Image Section */}
          <div className="hidden lg:block lg:col-span-2">
            <img
              className="rounded-xl"
              src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2FtestimoniShort.jpg?alt=media&token=b8f5f627-9de9-4944-af47-6639f8dacfaf"
              alt="Avatar"
            />
          </div>
          {/* End Col */}

          {/* Testimonial Section */}
          <div className="lg:col-span-4">
            {/* Blockquote */}
            <blockquote>
            <p className="text-xs font-semibold text-gray-500 tracking-wide uppercase mb-3 dark:text-neutral-200">
                Témoignage
              </p>
                <p className="text-xl font-medium text-gray-800 lg:text-2xl lg:leading-normal dark:text-neutral-200">
                Avertir les jeunes générations de la richesse de notre culture et de nos traditions est un devoir pour nous tous. C'est pourquoi nous avons créé cette association pour promouvoir notre culture et nos traditions.
                </p>
              <footer className="mt-6">
                <div className="flex items-center">
                  <div className="lg:hidden shrink-0">
                  </div>
                  <div className="ml-4 lg:ml-0">
                    <p className="font-medium text-gray-800 dark:text-neutral-200">
                      Poea T.
                    </p>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Habitant de Raraka
                    </p>
                  </div>
                </div>
              </footer>
            </blockquote>
            {/* End Blockquote */}
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
      </div>
    </div>
  );
};

export default TestimonialsSection;
