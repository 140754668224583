// src/pages/About/About.tsx
import React from "react";
import FAQSection from "../../components/About/FAQSection.tsx";
import FeaturesSection from "../../components/shared/Feature/FeaturesSection.tsx";
import TeamSection from "../../components/About/Team/TeamMembers.tsx";


const About: React.FC = () => {
  return (
    <>
      <div className="about-page p-4 font-['Poppins'] flex flex-col items-center space-y-8">
        <TeamSection />
        <div className="w-full h-px bg-gray-300"></div>
        <FAQSection />

      <div className="w-full h-px bg-gray-300"></div>
      <div className="text-center">
        <h2 className="text-4xl text-gray-800 font-semibold mb-4">
          Nos Objectifs Face au Changement Climatique
        </h2>
        <p className="text-lg text-gray-700">
          Rester à l'écoute des besoins de notre communauté
        </p>
      </div>
      <FeaturesSection />
    </div>
    </>
  );
};

export default About;
