import React from "react";

interface GeneralSectionProps {
  formData: {
    firstName: string;
    lastName: string;
    country: string;
    preferences: string[];
  };
  isEditing: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const GeneralSection: React.FC<GeneralSectionProps> = ({
  formData,
  isEditing,
  handleChange,
  handleCheckboxChange,
}) => (
  <form className="mt-4 space-y-6">
    {/* Prénom */}
    <div className="space-y-1.5">
      <label className="text-sm font-medium text-gray-300">Prénom</label>
      <input
        type="text"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        disabled={!isEditing}
        className={`w-full px-3 py-1.5 bg-gray-700 border rounded-md text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors ${
          !isEditing ? "cursor-not-allowed opacity-50" : ""
        }`}
      />
    </div>

    {/* Nom */}
    <div className="space-y-1.5">
      <label className="text-sm font-medium text-gray-300">Nom</label>
      <input
        type="text"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        disabled={!isEditing}
        className={`w-full px-3 py-1.5 bg-gray-700 border rounded-md text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors ${
          !isEditing ? "cursor-not-allowed opacity-50" : ""
        }`}
      />
    </div>

    <p className="text-sm text-gray-400 font-semibold">Les informations suivantes sont optionnelles.</p>

    {/* Select Input */}
    <div className="space-y-1.5">
      <label className="text-sm font-medium text-gray-300">Pays</label>
      <select
        name="country"
        value={formData.country}
        onChange={handleChange}
        disabled={!isEditing}
        className={`w-full px-3 py-1.5 bg-gray-700 border rounded-md text-gray-100 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors ${
          !isEditing ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        <option value="">Sélectionner un pays</option>
        <option value="United States">États-Unis</option>
        <option value="Canada">Canada</option>
        <option value="France">France</option>
        <option value="Germany">Allemagne</option>
      </select>
    </div>
  </form>
);

export default GeneralSection;
