import React from "react";
import { Link } from "react-router-dom";

const CardBlogContent: React.FC = () => {
  const articleId = 1;

  return (
    <div className="sm:order-1">
      <p className="mb-5 inline-flex items-center gap-1.5 py-1.5 px-3 rounded-md text-xs font-medium bg-gray-100 text-gray-800 dark:bg-neutral-800 dark:text-neutral-200">
        Nos projets
      </p>

      <h2 className="text-2xl font-bold md:text-3xl lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight text-gray-800 dark:text-neutral-200">
        <Link
          className="hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-neutral-300 dark:hover:text-white dark:focus:text-white"
          to={`/blog/${articleId}`}
        >
          Laisser une empreinte durable sur la planète avec nos projets
        </Link>
      </h2>

      <div className="mt-6 sm:mt-10 flex items-center">
        <div className="shrink-0">
          <img
            className="size-10 sm:h-14 sm:w-14 rounded-full"
            src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fpresident.c7ed59aa4ae09b8031ac.png?alt=media&token=f88cb692-396d-4084-8ba1-fcbd1e4bf8f1"
            alt="Author"
          />
        </div>

        <div className="ms-3 sm:ms-4">
          <p className="sm:mb-1 font-semibold text-gray-800 dark:text-neutral-200">
            Matthieu B
          </p>
          <p className="text-xs text-gray-500 dark:text-neutral-500">
            Président de Teoraoraraka
          </p>
        </div>
      </div>

      <p className="mt-5 text-lg text-gray-800 dark:text-neutral-200">
        Nous avons pour objectif de laisser une empreinte durable sur la planète avec nos projets. Nous nous engageons à protéger l'environnement et à promouvoir le développement durable. Nous croyons que nous pouvons tous faire une différence et contribuer à un avenir meilleur pour tous.
      </p>

      <div className="mt-5">
        <Link
          className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
          to={`/blog/${articleId}`}
        >
          Read more
          <svg
            className="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default CardBlogContent;
