// src/components/layout/Layout.tsx
import React from "react";
import Navbar from "../navigation/Navbar.tsx";
import Footer from "../navigation/Footer.tsx";

type LayoutProps = {
    children: React.ReactNode;
  };
  
  const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
    );
  };
  
  export default Layout;