import React, { useEffect } from "react";

const Accordion: React.FC = () => {
  useEffect(() => {
    // Initialise manuellement les interactions de l'accordéon
    const accordions = document.querySelectorAll("[data-accordion-target]");
    
    const handleClick = (accordion: Element) => {
      const targetId = accordion.getAttribute("data-accordion-target");
      const targetElement = document.getElementById(targetId!.substring(1));

      if (targetElement) {
        const isHidden = targetElement.classList.contains("hidden");

        // Ferme tous les autres accordéons
        document.querySelectorAll("[data-accordion-target]").forEach((btn) => {
          const id = btn.getAttribute("data-accordion-target");
          if (id !== targetId) {
            const element = document.querySelector(id!);
            element?.classList.add("hidden");
            btn.querySelector("svg")?.classList.remove("rotate-180");
          }
        });

        // Toggle l'accordéon actuel
        if (isHidden) {
          targetElement.classList.remove("hidden");
          accordion.querySelector("svg")?.classList.add("rotate-180");
          accordion.setAttribute("aria-expanded", "true");
        } else {
          targetElement.classList.add("hidden");
          accordion.querySelector("svg")?.classList.remove("rotate-180");
          accordion.setAttribute("aria-expanded", "false");
        }
      }
    };

    const clickHandlers = new Map<Element, () => void>();
    
    accordions.forEach((accordion) => {
      const handler = () => handleClick(accordion);
      clickHandlers.set(accordion, handler);
      accordion.addEventListener("click", handler);
    });

    // Cleanup event listeners
    return () => {
      accordions.forEach((accordion) => {
        const handler = clickHandlers.get(accordion);
        if (handler) {
          accordion.removeEventListener("click", handler);
        }
      });
    };
  }, []);
  return (
    <div
          data-accordion-target="accordion-flush-body-1"
      className="mt-8 mx-4 lg:mx-16"
      data-accordion="collapse"
    >
      {/* Section 1 */}
      <h2 id="accordion-flush-heading-1">
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-gray-500 border-b border-gray-200 gap-3 focus:outline-none"
          data-accordion-target="#accordion-flush-body-1"
          aria-expanded="false"
          aria-controls="accordion-flush-body-1"
        >
          <span>Quels sont les valeurs que vous souhaitez mettre en avant ?</span>
          <svg
            className="w-4 h-4 shrink-0 transition-transform"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-flush-body-1"
        className="hidden transition-all duration-300"
        aria-labelledby="accordion-flush-heading-1"
      >
        <div className="py-5 border-b border-gray-200">
          <p className="mb-2 text-gray-500">
            En tant qu'association à but non lucratif engagée dans la
            préservation de l'environnement et le développement durable des
            Tuamotu, nous avons à cœur de communiquer clairement notre mission et
            nos actions.
          </p>
          <p className="text-gray-500">
            Notre site web a été conçu pour être simple, efficace et accessible à
            tous, pour vous offrir une expérience utilisateur agréable tout en vous
            informant sur les enjeux auxquels sont confrontés les Tuamotu.
          </p>
        </div>
      </div>

      {/* Section 2 */}
      <h2 id="accordion-flush-heading-2">
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-gray-500 border-b border-gray-200 gap-3 focus:outline-none"
          data-accordion-target="#accordion-flush-body-2"
          aria-expanded="false"
          aria-controls="accordion-flush-body-2"
        >
          <span>Qui sommes-nous ?</span>
          <svg
            className="w-4 h-4 shrink-0 transition-transform"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-flush-body-2"
        className="hidden transition-all duration-300"
        aria-labelledby="accordion-flush-heading-2"
      >
        <div className="py-5 border-b border-gray-200">
          <p className="mb-2 text-gray-500">
            Teoraoraka est une association à but non lucratif qui œuvre pour la
            protection de l'environnement et la préservation de la biodiversité.
            Nous croyons en un avenir où l'homme et la nature vivent en harmonie.
          </p>
          <p className="text-gray-500">
            Nos actions s'articulent autour de plusieurs axes : sensibilisation du
            public, protection des espaces naturels, soutien à la recherche
            scientifique, et promotion de pratiques éco-responsables.
          </p>
        </div>
      </div>

      {/* Section 3 */}
      <h2 id="accordion-flush-heading-3">
        <button
          type="button"
          className="flex items-center justify-between w-full py-5 font-medium text-gray-500 border-b border-gray-200 gap-3 focus:outline-none"
          data-accordion-target="#accordion-flush-body-3"
          aria-expanded="false"
          aria-controls="accordion-flush-body-3"
        >
          <span>Quels sont nos objectifs ?</span>
          <svg
            className="w-4 h-4 shrink-0 transition-transform"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-flush-body-3"
        className="hidden transition-all duration-300"
        aria-labelledby="accordion-flush-heading-3"
      >
        <div className="py-5 border-b border-gray-200">
          <p className="mb-2 text-gray-500">
            Nous avons pour mission de préserver la biodiversité, promouvoir le
            développement durable, sensibiliser à l'environnement et soutenir des
            initiatives locales pour construire un avenir plus vert.
          </p>
          <p className="text-gray-500">
            Rejoignez-nous pour faire la différence et protéger les richesses
            naturelles et culturelles des Tuamotu.
          </p>
        </div>
      </div>
    </div>
    );
};


export default Accordion;
