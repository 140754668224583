import { getFirestore, doc, setDoc, getDoc, serverTimestamp, updateDoc } from "firebase/firestore";
import app from "../firebase/firebaseApp.ts";

const db = getFirestore(app);

/**
 * Ajouter un utilisateur à Firestore
 */
export const addUserToCollection = async (
    userId: string,
    email: string,
    firstName: string,
    lastName: string
  ) => {
    try {
      const userRef = doc(db, "users", userId);
      await setDoc(userRef, {
        email,
        firstName,
        lastName,
        role: "user", // Rôle par défaut
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
        country: null,
        preferences: [],
        notifications: {
          emailNotifications: false,
          smsNotifications: false,
          pushNotifications: false,
        },
        language: "fr",
        theme: "light",
      });
      console.log("Utilisateur ajouté avec succès.");
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'utilisateur :", error);
      throw error;
    }
  };
  
  
  
  /**
   * Récupérer le profil utilisateur depuis Firestore à partir de l'UID.
   */
  export const getUserProfile = async (uid: string) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const docSnap = await getDoc(userDocRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        throw new Error("User profile not found.");
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  };
  
  /**
   * Mettre à jour le profil utilisateur
   */
  export const updateUserProfile = async (userId: string, data: Record<string, any>) => {
    try {
      const userDoc = doc(db, "users", userId);
      const { firstName, lastName, country, preferences, notifications, language, theme } = data;
      const updateData: Record<string, any> = {
        firstName,
        lastName,
        lastLogin: serverTimestamp(),
      };
      if (country !== undefined) updateData.country = country;
      if (preferences !== undefined) updateData.preferences = preferences;
      if (notifications !== undefined) updateData.notifications = notifications;
      if (language !== undefined) updateData.language = language;
      if (theme !== undefined) updateData.theme = theme;
      await updateDoc(userDoc, updateData);
      console.log("Profil utilisateur mis à jour avec succès.");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du profil utilisateur :", error);
      throw error;
    }
  };