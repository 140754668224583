import React, { useState } from "react";
import { deleteAccount } from "../../../services/auth/index.ts";

const DeleteAccountForm: React.FC = () => {
  const [confirmation, setConfirmation] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    setMessage(null);
    setError(null);

    try {
      await deleteAccount();
      setMessage("Votre compte a été supprimé avec succès.");
    } catch (err: any) {
      setError(err.message || "Une erreur s'est produite lors de la suppression du compte.");
    }
  };

  return (
    <div className="max-w-lg mx-auto bg-gray-800 shadow-lg rounded-lg p-6 space-y-6">
      <h2 className="text-2xl font-bold text-gray-100">
        Supprimer le compte
      </h2>
      <p className="text-sm text-gray-300">
        Cette action est irréversible. Toutes vos données seront supprimées.
      </p>

      {/* Success & Error Messages */}
      {message && (
        <p className="text-sm text-center text-green-500 bg-green-100 rounded-lg py-2">
          {message}
        </p>
      )}
      {error && (
        <p className="text-sm text-center text-red-500 bg-red-100 rounded-lg py-2">
          {error}
        </p>
      )}

      {/* Confirmation Flow */}
      {!confirmation ? (
        <button
          onClick={() => setConfirmation(true)}
          className="w-full py-3 text-center text-white bg-red-600 hover:bg-red-700 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          Supprimer mon compte
        </button>
      ) : (
        <div className="space-y-4">
          <p className="text-sm text-gray-300">
            Confirmez-vous la suppression de votre compte ?
          </p>
          <div className="flex justify-between">
            <button
              onClick={handleDelete}
              className="w-1/2 py-3 text-center text-white bg-red-600 hover:bg-red-700 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              Oui, supprimer
            </button>
            <button
              onClick={() => setConfirmation(false)}
              className="w-1/2 ml-4 py-3 text-center text-white bg-gray-600 hover:bg-gray-700 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Annuler
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccountForm;
