import React from "react";
import IconBlock from "./IconBlock.tsx";

const IconBlockContainer = () => {
    const iconBlocks = [
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
                </svg>
            ),
            title: "Documentation de pointe",
            description:
                "Notre documentation et nos bibliothèques client complètes contiennent tout ce dont une association écologique a besoin pour mettre en œuvre des solutions durables rapidement.",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                    <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
                </svg>
            ),
            title: "Support communautaire des développeurs",
            description:
                "Nous contribuons activement à des projets open-source pour promouvoir des solutions écologiques, en donnant en retour à la communauté par le développement, des correctifs et des parrainages.",
        },
        {
            icon: (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M7 10v12" />
                    <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
                </svg>
            ),
            title: "Simple et abordable",
            description:
                "Des solutions simples et abordables pour les associations écologiques, facilitant la gestion et la mise en œuvre de projets durables.",
        },
    ];

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid md:grid-cols-2 gap-12">
                <div className="lg:w-3/4">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        Nous proposerons des solutions à travers des projets associatifs écologiques
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-neutral-400">
                        Nous proposerons des solutions à travers des projets associatifs écologiques pour la protection de l'environnement et la promotion du développement durable. Nous croyons que nous pouvons tous faire une différence et contribuer à un avenir meilleur pour tous.
                    </p>
                    <p className="mt-5">
                        <a
                            className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline focus:outline-none focus:underline font-medium dark:text-blue-500"
                            href="/contact"
                        >
                            Contactez-nous pour en savoir plus
                            <svg
                                className="shrink-0 size-4 transition ease-in-out group-hover:translate-x-1 group-focus:translate-x-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="m9 18 6-6-6-6" />
                            </svg>
                        </a>
                    </p>
                </div>
                <div className="space-y-6 lg:space-y-10">
                    {iconBlocks.map((block, index) => (
                        <IconBlock
                            key={index}
                            icon={block.icon}
                            title={block.title}
                            description={block.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default IconBlockContainer;
