import React from "react";

interface IconBlockProps {
  icon: React.ReactElement;
  title: string;
  description: string;
}

const IconBlock: React.FC<IconBlockProps> = ({ icon, title, description }) => {
  return (
    <div className="flex gap-x-5 sm:gap-x-8">
      <span className="shrink-0 inline-flex justify-center items-center size-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
        {icon}
      </span>
      <div className="grow">
        <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-neutral-200">
          {title}
        </h3>
        <p className="mt-1 text-gray-600 dark:text-neutral-400">{description}</p>
      </div>
    </div>
  );
};

export default IconBlock;
