import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  UserPlusIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../../hooks/useAuth.ts";

const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleProfileRedirect = () => {
    navigate("/profile");
  };

  return (
    <nav className="relative z-50 bg-white border-b border-gray-200 dark:bg-gray-900 w-full">
      {/* Main Container */}
      <div className="flex items-center justify-between px-6 py-3">
        {/* Logo */}
        <div className="flex items-center">
          <Link
            to="/"
            className="text-2xl font-bold text-gray-800 dark:text-white"
          >
            <div className="flex flex-col">
              <span
                className="text-lg font-semibold whitespace-nowrap text-black"
                style={{ fontFamily: "'Oswald', sans-serif" }}
              >
                Teoraoraraka
              </span>
              <span
                className="text-sm text-gray-600 -mt-1"
                style={{ fontFamily: "'Bebas Neue', cursive" }}
              >
                From France to Tuamotu
              </span>
            </div>
          </Link>
        </div>

        {/* Hamburger (Mobile) */}
        <button
          className="md:hidden p-2 text-gray-600 dark:text-white"
          onClick={() => setMobileOpen(!mobileOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Search Bar (Hidden on small screens) */}
        <div className="hidden md:flex flex-grow mx-8">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Rechercher..."
              className="w-full p-3 pl-5 text-sm text-gray-900 border border-gray-300 rounded-full bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-black-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black-500 dark:focus:border-black-500"
            />
            <button
              className="absolute inset-y-0 right-0 px-4 py-2 text-white bg-blue-600 rounded-full hover:bg-blue-700 focus:outline-none"
              aria-label="Rechercher"
            >
              <MagnifyingGlassIcon className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Right-side Options */}
        <div className="hidden md:flex items-center space-x-6">
          {/* Language Selector */}
          <div className="flex items-center space-x-1">
            <img
              src="https://flagcdn.com/w40/pf.png"
              alt="Langue"
              className="w-5 h-5 rounded-full"
            />
            <span className="text-sm text-gray-700 dark:text-white">
              French Polynesia
            </span>
          </div>

          {/* User Auth */}
          {user ? (
            <>
              <div
                className="flex items-center space-x-1 text-sm text-gray-800 dark:text-white cursor-pointer"
                onClick={handleProfileRedirect}
              >
                <UserCircleIcon className="w-5 h-5" />
                <span>{user.email}</span>
              </div>
              <button
                onClick={logout}
                className="flex items-center text-sm text-gray-700 hover:underline dark:text-white"
              >
                <ArrowRightOnRectangleIcon className="h-5 w-5 mr-1" />
                Déconnexion
              </button>
            </>
          ) : (
            <div className="flex items-center space-x-2">
              <Link
                to="/login"
                className="text-sm text-gray-700 dark:text-white hover:underline"
              >
                Connexion
              </Link>
              <span className="text-sm text-gray-500 dark:text-gray-400">/</span>
              <Link
                to="/register"
                className="text-sm text-gray-700 dark:text-white hover:underline"
              >
                Inscription
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Menu */}
      {mobileOpen && (
        <div className="block md:hidden px-6 py-2 bg-gray-100 dark:bg-gray-800 space-y-2">
          <div className="flex flex-col items-start">
            <Link to="/" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400 mb-2">
              Accueil
            </Link>
            <Link to="/projects" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400 mb-2">
              Projets
            </Link>
            <Link to="/events" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400 mb-2">
              Événements
            </Link>
            <Link to="/contact" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400 mb-2">
              Contact
            </Link>
            <Link to="/about" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400">
              A Propos
            </Link>
          </div>
        </div>
      )}

      {/* Bottom Navigation (Visible on large screens) */}
      <div className="hidden md:block bg-gray-100 px-6 py-2 dark:bg-gray-800">
        <div className="flex items-center space-x-8 text-sm font-medium text-gray-700 dark:text-gray-300">
          <Link to="/" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400">
            Accueil
          </Link>
          <Link to="/projects" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400">
            Projets
          </Link>
          <Link to="/events" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400">
            Événements
          </Link>
          <Link to="/contact" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400">
            Contact
          </Link>
          <Link to="/about" className="hover:underline hover:text-blue-600 dark:hover:text-blue-400">
            A Propos
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;