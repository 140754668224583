import React from "react";
import InputField from "./InputField.tsx";
import SubscribeButton from "./SubscribeButton.tsx";

const SubscribeForm: React.FC = () => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    alert("Formulaire soumis !");
  };

  return (
    <section className="contact-section bg-gray-100 py-12">
      <div className="max-w-3xl mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center mb-6">Abonnez-vous à notre newsletter</h2>
        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-center justify-center gap-4">
          <InputField
            id="email"
            name="email"
            type="email"
            placeholder="Entrez votre email"
            className="w-full sm:w-auto flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <SubscribeButton text="S'abonner" className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300" />
        </form>
      </div>
    </section>
  );
};

export default SubscribeForm;
