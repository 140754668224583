import React, { useState } from "react";
import { addContactMessage } from "../../services/firestore/contactCollection.ts";

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    details: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const { firstname, lastname, email, details } = formData;
      const fullName = `${firstname} ${lastname}`;
      await addContactMessage(fullName, email, details);
      setSuccessMessage("Votre message a été envoyé avec succès !");
      setFormData({ firstname: "", lastname: "", email: "", phone: "", details: "" });
    } catch (error) {
      console.error("Erreur lors de l'envoi du message : ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col border rounded-xl p-6 sm:p-8 lg:p-10 dark:border-neutral-700 w-full max-w-2xl">
        <h2 className="mb-8 text-2xl font-semibold text-gray-800 dark:text-neutral-200">Posez votre question</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Nom"
                value={formData.firstname}
                onChange={handleChange}
                required
                className="py-4 px-5 block w-full border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500"
              />
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Prénom"
                value={formData.lastname}
                onChange={handleChange}
                required
                className="py-4 px-5 block w-full border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500"
              />
            </div>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
              className="py-4 px-5 block w-full border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500"
            />
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="Numéro de téléphone"
              value={formData.phone}
              onChange={handleChange}
              className="py-4 px-5 block w-full border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500"
            />
            <textarea
              name="details"
              id="details"
              rows={5}
              placeholder="Votre question"
              value={formData.details}
              onChange={handleChange}
              required
              className="py-4 px-5 block w-full border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full py-4 px-5 mt-6 inline-flex justify-center items-center gap-x-2 text-base font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
          >
            {isSubmitting ? "Envoi en cours..." : "Envoyez votre message"}
          </button>
          {successMessage && (
            <p className="mt-4 text-center text-base text-green-500">
              {successMessage}
            </p>
          )}
          <p className="mt-4 text-center text-base text-gray-500 dark:text-neutral-500">
            Nous vous répondrons dans les plus brefs délais.
          </p>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
