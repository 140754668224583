import { getFirestore, collection, query, getDocs, addDoc, where, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import app from "../firebase/firebaseApp.ts";

const db = getFirestore(app);

export const addContactMessage = async (name: string, email: string, message: string) => {
  try {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (!user) {
      throw new Error("Utilisateur non authentifié.");
    }

    const contactRef = collection(db, "contacts");
    await addDoc(contactRef, {
      userId: user.uid,
      name,
      email,
      message,
      createdAt: serverTimestamp(),
      status: "unread",
      resolvedAt: null,
      response: null,
      messageId: `msg-${Date.now()}`, // Exemple d'ID unique basé sur le timestamp
    });
    console.log("Message de contact ajouté");
  } catch (error) {
    console.error("Erreur lors de l'ajout du message de contact : ", error);
    throw error;
  }
};

/**
 * Récupérer les messages de la collection contacts
 */
export const getUserMessages = async (userId: string) => {
    try {
      const contactsRef = collection(db, "contacts");
      const q = query(contactsRef, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        return []; // Aucun message trouvé
      }
  
      return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Erreur lors de la récupération des messages :", error);
      throw error;
    }
  };