import React from "react";

interface PreferencesSectionProps {
  formData: {
    language: string;
    theme: string;
  };
  isEditing: boolean;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const PreferencesSection: React.FC<PreferencesSectionProps> = ({
  formData,
  isEditing,
  handleChange,
}) => (
  <form className="mt-6 space-y-6">
    <h2 className="text-xl font-bold text-white mb-4">Préférences</h2>

    {/* Language Preference */}
    <div>
      <label className="block text-sm font-medium text-gray-300">Langue</label>
      <select
        name="language"
        value={formData.language}
        onChange={handleChange}
        disabled={!isEditing}
        className={`w-full px-4 py-2 bg-gray-700 border rounded-md text-gray-100 focus:ring-blue-500 focus:border-blue-500 ${
          !isEditing ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        <option value="fr">Français</option>
        <option value="en">Anglais</option>
        <option value="es">Espagnol</option>
        <option value="de">Allemand</option>
      </select>
    </div>

    {/* Theme Preference */}
    <div>
      <label className="block text-sm font-medium text-gray-300">Thème</label>
      <select
        name="theme"
        value={formData.theme}
        onChange={handleChange}
        disabled={!isEditing}
        className={`w-full px-4 py-2 bg-gray-700 border rounded-md text-gray-100 focus:ring-blue-500 focus:border-blue-500 ${
          !isEditing ? "cursor-not-allowed opacity-50" : ""
        }`}
      >
        <option value="light">Clair</option>
        <option value="dark">Sombre</option>
      </select>
    </div>
  </form>
);

export default PreferencesSection;
