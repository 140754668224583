import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser, sendPasswordResetEmail } from "../../services/auth/index.ts";
import { handleEmailLinkSignIn } from "../../services/auth/handleEmailLink.ts";
import { useAuth } from "../../hooks/useAuth.ts";
import AuthLayout from "../../components/layout/AuthLayout.tsx";
import Toast from "../../components/notifications/Toast.tsx";

const Login: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [toastType, setToastType] =
    useState<"success" | "error" | "warning" | null>(null);
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const email = localStorage.getItem("emailForSignIn");
    if (email) {
      handleEmailLinkSignIn(email).then((status) => {
        if (status === "success") {
          setToastMessage("Connexion réussie avec le lien !");
          setToastType("success");
          navigate("/profile");
        } else if (status === "error") {
          setToastMessage("Erreur lors de la connexion avec le lien.");
          setToastType("error");
        } else {
          setToastMessage("Lien invalide ou expiré.");
          setToastType("warning");
        }
      });
    }
  }, [navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await loginUser(formData.email, formData.password);
      if (response.success && response.user) {
        auth.login(response.user);
        navigate("/profile");
      } else {
        setError(response.error || "Erreur inconnue lors de la connexion.");
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handlePasswordReset = async () => {
    setError(null);
    setResetSuccess(false);

    if (!formData.email) {
      setError("Veuillez entrer une adresse email valide pour réinitialiser votre mot de passe.");
      return;
    }

    try {
      await sendPasswordResetEmail(formData.email);
      setResetSuccess(true);
    } catch (err: any) {
      setError(err.message || "Erreur lors de l'envoi de l'email de réinitialisation.");
    }
  };

  return (
    <AuthLayout>
      {toastMessage && toastType && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
      <section className="min-h-screen flex">
        {/* Formulaire côté gauche */}
        <div className="w-full lg:w-1/2 flex flex-col justify-center px-8 py-12 bg-gray-50 dark:bg-gray-900">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 dark:text-white">
              Connectez-vous avec{" "}
              <span className="text-blue-600 dark:text-blue-500">simplicité</span>
            </h1>
            <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
              Accédez à votre compte pour gérer vos services.
            </p>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              {error && (
                <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
              )}
              {resetSuccess && (
                <p className="text-sm text-green-600 dark:text-green-400">
                  Email de réinitialisation envoyé !
                </p>
              )}

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium dark:text-white"
                >
                  Adresse email
                </label>
                <input
                  type="email"
                  id="email"
                  className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium dark:text-white"
                >
                  Mot de passe
                </label>
                <input
                  type="password"
                  id="password"
                  className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-900 dark:border-gray-700"
                  placeholder="Mot de passe"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>

              <div className="flex items-center justify-between">
                <a
                  href="#"
                  onClick={handlePasswordReset}
                  className="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
                >
                  Mot de passe oublié ?
                </a>
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm"
              >
                Connexion
              </button>
            </form>
          </div>
        </div>

        {/* Image côté droit */}
        <div
          className="hidden lg:block lg:w-1/2 bg-cover bg-center"
          style={{
            backgroundImage:
              "url('https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fimage-c5.png?alt=media&token=6efc284e-fe37-4478-b67e-62176cfc0692')",
          }}
        />
      </section>
    </AuthLayout>
  );
};

export default Login;
