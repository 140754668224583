import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateEmail as firebaseUpdateEmail,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  deleteUser,
} from "firebase/auth";
import auth from "../firebase/firebaseAuth.ts";

/**
 * Inscription avec email, mot de passe et vérification par email
 */
export const registerUser = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(userCredential.user);
    return { success: true, user: userCredential.user };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

/**
 * Connexion avec email et mot de passe
 */
export const loginUser = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return { success: true, user: userCredential.user };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

/**
 * Réinitialisation du mot de passe
 */
export const sendPasswordResetEmail = async (email: string) => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
  } catch (error: any) {
    throw new Error(error.message);
  }
};

/**
 * Mise à jour de l'email de l'utilisateur
 */
interface UpdateEmailResult {
  success: boolean;
  error: string | null;
}

export const updateUserEmail = async (email: string, password: string, newEmail: string): Promise<UpdateEmailResult> => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    await firebaseUpdateEmail(userCredential.user, newEmail);
    return { success: true, error: null };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

/**
 * Suppression du compte utilisateur
 */
export const deleteAccount = async () => {
  if (!auth.currentUser) {
    throw new Error("Aucun utilisateur authentifié.");
  }

  try {
    await deleteUser(auth.currentUser);
  } catch (error: any) {
    throw new Error(error.message || "Une erreur s'est produite lors de la suppression du compte.");
  }
};
