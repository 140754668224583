import React from 'react';

const BlogContent: React.FC = () => {
  return (
    <div className="py-8 lg:pe-8">
      <div className="space-y-5 lg:space-y-8">

        <h2 className="text-3xl font-bold lg:text-5xl dark:text-white">
          Annonce de Création de l'Association "Teoraoraraka"
        </h2>

        <div className="flex items-center gap-x-5">
          <a
            className="inline-flex items-center gap-1.5 py-1 px-3 sm:py-2 sm:px-4 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none dark:bg-neutral-800 dark:text-neutral-200"
          >
           Nouvelles de l'association
          </a>
          <p className="text-xs sm:text-sm text-gray-800 dark:text-neutral-200">
            Janvier 23, 2025
          </p>
        </div>

        <p className="text-lg text-gray-800 dark:text-neutral-200">
          Apres Avoir été créée en 2024, l'association "Teoraoraraka" a pour objectif de promouvoir la culture et les traditions polynésiennes. Nous sommes fiers de faire partie de la création d'une culture plus ouverte.
          Nous nous sommes rendu compte qu'il etait important de sensibiliser les jeunes générations à la richesse de notre culture et de nos traditions. C'est pourquoi nous avons créé cette association pour promouvoir notre culture et nos traditions.
        </p>

        <p className="text-lg text-gray-800 dark:text-neutral-200">
          Nous allons organiser des événements culturels, des ateliers de danse et de musique, des expositions d'art et des conférences sur la culture polynésienne. Nous espérons que ces événements permettront de sensibiliser les jeunes générations à la richesse de notre culture et de nos traditions.
        </p>

        {/* Images */}
        <div className="text-center">
          <div className="grid lg:grid-cols-2 gap-3">
            <div className="grid grid-cols-2 lg:grid-cols-1 gap-3">
              <figure className="relative w-full h-60">
                <img
                  className="absolute top-0 start-0 object-cover rounded-xl w-full h-full"
                  src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fschool.jpg?alt=media&token=346b4f12-7953-454f-b9f8-31ae15c533f6"
                  alt="Blog Image"
                />
              </figure>
              <figure className="relative w-full h-60">
                <img
                  className="absolute top-0 start-0 object-cover rounded-xl w-full h-full"
                  src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fhometown.jpg?alt=media&token=5e5257aa-9f1c-4e0f-8eed-2d597e3c8084"
                  alt="Blog Image"
                />
              </figure>
            </div>
            <figure className="relative w-full h-72 sm:h-96 lg:h-full">
              <img
                className="absolute top-0 start-0 object-cover rounded-xl w-full h-full"
                src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fchurch.jpg?alt=media&token=91c8572d-14c5-400c-b4da-74f236ed7e1e"
                alt="Blog Image"
              />
            </figure>
          </div>
          <span className="mt-3 block text-sm text-gray-500 dark:text-neutral-500">
            Nous ferons plus d'annonces d'ici peu.
          </span>
        </div>

        {/* Additional Content */}
        <p className="text-lg text-gray-800 dark:text-neutral-200">
          Nous sommes impatients de partager notre passion pour la culture polynésienne et nos traditions avec vous. Nous espérons que vous serez nombreux à nous rejoindre dans cette aventure.
        </p>
      </div>
    </div>
  );
};

export default BlogContent;
