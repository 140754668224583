// src/pages/Contact/Contact.tsx
import React from "react";
import SubscribeSection from "../../components/Contact/Form/SubscribeSection.tsx";
import ContactUS from "../../components/Contact/ContactUsSection.tsx";

const Contact: React.FC = () => {
  return (
    <div className="about-page p-4 font-['Poppins'] flex flex-col items-center space-y-8">
        
        <div className="w-full h-px bg-gray-300"></div>
        <ContactUS />
        
        <div className="w-full">
            <div className="w-full h-px bg-gray-300"></div>
            <SubscribeSection />
        </div>
    </div>
  );
};

export default Contact;
