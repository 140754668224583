import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleById } from "../../services/firestore/articleCollection.ts";
import BlogSidebar from "./BlogSidebar.tsx";

const BlogArticle: React.FC = () => {
  const { id } = useParams();
  const [article, setArticle] = useState<any | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (id) {
        const data = await getArticleById(id);
        setArticle(data);
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) {
    return <p>Chargement...</p>;
  }

  return (
    <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6">
        <div className="lg:col-span-2">
          <h1 className="text-3xl font-bold lg:text-5xl dark:text-white">{article.title}</h1>
          <div className="flex items-center gap-x-5 mt-4">
            <span className="inline-flex items-center gap-1.5 py-1 px-3 rounded-full text-xs sm:text-sm bg-gray-100 text-gray-800 dark:bg-neutral-800 dark:text-neutral-200">
              {article.tags?.join(", ")}
            </span>
            <p className="text-xs sm:text-sm text-gray-600 dark:text-neutral-200">
              {new Date(article.createdAt.toDate()).toLocaleDateString()}
            </p>
          </div>
          <p className="mt-6 text-lg text-gray-800 dark:text-neutral-200">{article.content}</p>
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {article.images?.map((url: string, index: number) => (
              <img
                key={index}
                className="rounded-lg object-cover w-full h-48"
                src={url}
                alt={`Image ${index + 1}`}
              />
            ))}
          </div>
        </div>
        <div className="lg:col-span-1">
          <BlogSidebar author={article.author} />
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;

