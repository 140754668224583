// src/pages/Legal/Terms.tsx
import React from "react";

const Terms: React.FC = () => {
  return (
    <div className="terms-page p-4">
      <h1 className="text-3xl font-bold mb-4">Termes et Conditions</h1>
      <p>
        Consultez nos termes et conditions pour comprendre les règles et
        responsabilités associées à l'utilisation de notre plateforme.
      </p>
    </div>
  );
};

export default Terms;
