import React from "react";

interface AvatarProps {
  avatar: string;
  name: string;
  date: string;
}

const Avatar: React.FC<AvatarProps> = ({ avatar, name, date }) => {
  return (
    <div className="flex items-center">
      <div className="shrink-0">
        <img
          className="size-[46px] border-2 border-white rounded-full"
          src={avatar}
          alt={name}
        />
      </div>
      <div className="ms-2.5 sm:ms-4">
        <h4 className="font-semibold text-white">{name}</h4>
        <p className="text-xs text-white/80">{date}</p>
      </div>
    </div>
  );
};

export default Avatar;
