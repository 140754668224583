import React from 'react';
import { Link } from 'react-router-dom';

const BlogList: React.FC = () => {
  const articles = [
    {
      id: '1',
      title: 'Annonce de Création de l\'Association',
      snippet: 'Découvrez l\'histoire derrière la création de notre association...',
      date: '23 Janvier 2025',
    },
    {
      id: '2',
      title: 'Explorer la riche biodiversité de la Polynésie',
      snippet: 'Un voyage au cœur des merveilles naturelles...',
      date: '10 Février 2025',
    },
  ];

  return (
    <div className="py-8">
      {articles.map((article) => (
        <div key={article.id} className="mb-6">
          <h2 className="text-lg font-semibold">
            <Link to={`/blog/${article.id}`} className="text-blue-600 hover:underline">
              {article.title}
            </Link>
          </h2>
          <p className="text-sm text-gray-600">{article.snippet}</p>
          <p className="text-xs text-gray-500">{article.date}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
