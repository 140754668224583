import React from "react";

const CardBlogImage = () => {
  return (
    <div className="sm:order-2">
      <div className="relative pt-[50%] sm:pt-[100%] rounded-lg">
        <img
          className="size-full absolute top-0 start-0 object-cover rounded-lg"
          src="https://firebasestorage.googleapis.com/v0/b/teoraoraraka-asso.firebasestorage.app/o/images%2Fchurch.jpg?alt=media&token=91c8572d-14c5-400c-b4da-74f236ed7e1e"
          alt="Blog Image"
        />
      </div>
    </div>
  );
};

export default CardBlogImage;
