import React from "react";

interface SubscribeButtonProps {
  text: string;
  onClick?: () => void;
}

const SubscribeButton: React.FC<SubscribeButtonProps> = ({ text, onClick }) => {
  return (
    <button
      className="w-full sm:w-auto whitespace-nowrap py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SubscribeButton;
