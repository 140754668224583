import React from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen">
      {/* Bouton de retour à l'accueil */}
      <div className="absolute top-4 left-4">
        <Link
          to="/"
          className="flex items-center text-gray-700 dark:text-gray-300"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Retour à l'accueil
        </Link>
      </div>

      {/* Conteneur principal */}
      <div>{children}</div>
    </div>
  );
};

export default AuthLayout;
