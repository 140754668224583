// src/pages/Projects/Projects.tsx
import React from "react";
import CardBlogContainer from "../../components/Project/Card/CardBlogContainer.tsx";
import BlogArticleContainer from "../../components/Project/BlogContainer/BlogCardContainer.tsx";
import IconBlockContainer from "../../components/Project/Description/IconBlockContainer.tsx";
const Projects: React.FC = () => {
  return (
    <div className="projects-page p-4 font-['Poppins']">
      <CardBlogContainer />


    <div className="about-page font-['Poppins'] flex flex-col items-center space-y-8">
      <div className="w-full h-px bg-gray-300"></div> 

      <div className="mx-auto px-4 sm:container">
      <div className="border-stroke dark:border-dark-3 border-b">
       <h2 className="text-dark mb-2 text-2xl font-semibold dark:text-white">
        Nos Projets Associatifs
       </h2>
       <p className="text-body-color dark:text-dark-6 mb-6 text-sm font-medium">
        Projets variés axés sur le développement communautaire, l'engagement social et la durabilité environnementale.
       </p>
      </div>
     </div>
    </div>
    <BlogArticleContainer />
    <div className="w-full h-px bg-gray-300"></div> 
    <IconBlockContainer />
    </div>
  );
};

export default Projects;
